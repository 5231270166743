.QueryCardChart * {
  cursor: pointer !important;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.markdown h1:first-child,
.markdown h2:first-child,
.markdown h3:first-child {
  margin-top: 0;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin-top: 1em;
  font-weight: 500;
  margin-bottom: 0.75em;
}

/* Header scaled values inspired from: https://www.smashingmagazine.com/2011/03/technical-web-typography-guidelines-and-techniques/#setting-a-scale */

.markdown h1 {
  font-size: 1.5em;
  line-height: 1em;
}

.markdown h2 {
  font-size: 1.375em;
  line-height: 1.0909em;
}

.markdown h3 {
  font-size: 1.25em;
  line-height: 1.2em;
}

.markdown h4 {
  font-size: 1.125em;
  line-height: 1.333em;
}

.markdown h5 {
  font-size: 1em;
  line-height: 1.5em;
}

.markdown h6 {
  font-size: 1em;
  line-height: 1.5em;
}

.markdown ul,
.markdown ol {
  padding-left: 1.85em;
  margin-bottom: 0.75em;
}

.markdown ul > ul,
.markdown ol > ol {
  margin-top: 0.25em;
}

.markdown li {
  margin-top: 0.25em;
}

.markdown p,
.markdown a {
  margin-bottom: 0.75em;
}

.markdown img {
  width: 90%;
  margin: auto;
}

.markdown code {
  margin-bottom: 0.75em;
}

.markdown li code {
  margin-bottom: 0;
}

.markdownSql {
  margin-bottom: 0.75em;
}

.markdownTable {
  margin-bottom: 0.75em;
}

.markdownChart {
  margin-bottom: 0.75em;
}

#atlas-chat button span:first-of-type {
  display: none !important;
}

#atlas-chat button span:nth-of-type(2) {
  display: none !important;
  /*margin-left: -13px !important;*/
  /*margin-right: -6px !important;*/
}

#atlas-chat button {
  width: 5vw !important;
  min-width: 50px !important;
  max-width: 70px !important;
  left: 0px !important;
  background: transparent !important;
  box-shadow: none !important;
}

.vega-bind select {
  border-width: 1px;
  border-radius: 5px;
}
.vega-bind span {
  margin-right: 5px;
  margin-left: 5px;
}

.vega-bind * {
  font-size: 12px;
}

.customVegaEmbed * {
  margin: auto;
}

.vega-embed summary {
  color: red;
  left: 0 !important;
  right: unset !important;
}

.vega-embed .vega-actions {
  left: -9px !important;
  right: unset !important;
}

.vega-embed .vega-actions::before {
  left: 14px !important;
  right: auto !important;
}

.vega-embed .vega-actions::after {
  left: 15px !important;
  right: auto !important;
}

.vega-embed.has-actions {
  padding-right: 0 !important;
}

.vega-embed {
  margin: auto !important;
}
