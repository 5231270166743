@page {
  margin: 4rem !important;
}

@media print {
  .page-break {
    break-after: auto;
    break-before: auto;
    break-inside: avoid;
    padding-bottom: 1.5rem;
  }
}
