.json-viewer > div > span:first-child {
  display: none;
}
.json-viewer > div > span:last-child {
  display: none;
}

.json-viewer > div > div > div > span > svg {
  display: none;
}

.json-viewer .MuiInputBase-root {
  border-radius: 5px;
  border-color: #4c6ef5;
  border-width: 2px;
  width: calc(90% - 20px);
  color: #343a40;
}
